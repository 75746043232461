<script>

    export let image;
    export let title;
    export let description;
    
</script>

<div class="hero banner">
    <img src={image} alt="{title}" width="1920" height="1080"/>
</div>

<div class="hero">
    <div class="hero-body container desc">
        <h2 class="title">{title}</h2>
        <p class="desc-text">{@html description}</p>
    </div>
</div>



<style lang="scss">.hero {
  background-color: whitesmoke; }

.banner {
  margin-top: -1rem; }
  .banner img {
    max-height: 60vh;
    -o-object-fit: cover;
       object-fit: cover; }

.desc {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto; }
  @media screen and (min-width: 769px), print {
    .desc {
      max-width: 70%; } }
  @media screen and (min-width: 1216px) {
    .desc {
      max-width: 50%; } }</style>