<script>
    import {Formatter} from '@utils/Formatter';
    import PromotionSection from '@components/index/PromotionSection.svelte';
    import WelcomeSection from '@components/index/WelcomeSection.svelte';

    export let title;
    export let image = null;
    export let description = null;
    export let sliderImages = []
</script>

<svelte:head>
	<title>{title}</title>

    {#if image != null}
        <meta property="og:image" content={image} />
    {/if}
    <meta property="og:title" content={title} />
    <meta property="og:url" content={Formatter.url('/', true)}/>
</svelte:head>


{#each sliderImages as slide, index}
    {#if index === 0}
        <WelcomeSection {...slide} {index}/>
    {:else}
        <PromotionSection {...slide} index={index - 1}/>
    {/if}
{/each}

{#if description}

<div class="hero">
    <article class="hero-body container content">
        {@html description}
    </article>
</div>
{/if}

<style lang="scss">.hero {
  background-color: whitesmoke; }</style>