<script>

export let image;
export let title;
export let description;
export let buttonText;
export let url;
export let index;

</script>


<div class="container section">
    <div class="columns" class:right={index % 2 == 1}>
        <div class="column">
            {#if url}
                <a href={url}><img src={image} width="800" height="600" alt="{title}"/></a>
            {:else}
                <img src={image} width="800" height="600" alt="{title}"/>
            {/if}
            
        </div>
        <div class="column desc">
            <h2 class="title">{title}</h2>
            <p>{@html description}</p>
            {#if buttonText}
                <div class="btn py-4">
                    <a class="button is-primary" href="{url}">{buttonText}</a>
                </div>
            {/if}
        </div>
    </div>
</div>

<style lang="scss">.columns {
  align-items: center; }

.desc {
  display: flex;
  flex-direction: column;
  text-align: center; }

.right {
  flex-direction: row-reverse; }</style>